import React, { type FC, type PropsWithChildren } from 'react'

import Button from '../../../components/Button'

import styles from './styles.module.scss'
import { type StageChangeActions } from './types'

const FormBlockWithActions: FC<PropsWithChildren<StageChangeActions & { title: string }>> = ({
  title,
  children,
  previousStage,
  nextStage,
}) => {
  return (
    <div className={styles.formWithActions}>
      <div className={styles.blockTitle}>{title}</div>
      <div className={styles.formBlock}>{children}</div>
      <div className={styles.actionBlock}>
        {previousStage && (
          <Button className={`${styles.button} ${styles.prev}`} onClick={() => previousStage?.()}>
            Previous
          </Button>
        )}
        {nextStage && (
          <Button className={`${styles.button} ${styles.next}`} onClick={() => nextStage?.()}>
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

export default FormBlockWithActions
