import React from 'react'

import { CalendarMonth, HighlightOff } from '@mui/icons-material'
import classnames from 'classnames'
import ReactDatePicker from 'react-datepicker'

import styles from './styles.module.scss'

type DateInputProps = {
  selectedDate?: Date | null
  onChange: (date: Date | null) => void
  isDisabled: boolean
  minDate?: Date
  placeholderText?: string
  inputContainerClassName?: string
  iconClassName?: string
  inputClassName?: string
  isDisabledClassName?: string
  closeButtonClassName?: string
}

const DateInput: React.FC<DateInputProps> = ({
  selectedDate,
  onChange,
  isDisabled,
  minDate = new Date(),
  placeholderText = 'Select a date',
  inputContainerClassName,
  iconClassName,
  inputClassName,
  isDisabledClassName,
  closeButtonClassName,
}) => {
  return (
    <div className={classnames(styles.dateInputWrapper)}>
      <div
        className={classnames(styles.inputContainer, inputContainerClassName, {
          [isDisabledClassName || styles.isDisabled]: isDisabled,
        })}
      >
        <CalendarMonth
          className={classnames(styles.icon, iconClassName, { [isDisabledClassName || styles.isDisabled]: isDisabled })}
        />
        <ReactDatePicker
          selected={selectedDate}
          onChange={onChange}
          minDate={minDate}
          placeholderText={placeholderText}
          className={classnames(styles.input, 'date-picker-input', inputClassName, {
            [isDisabledClassName || styles.isDisabled]: isDisabled,
          })}
          dateFormat="dd/MM/yyyy"
          isClearable={false}
          showPopperArrow={false}
          disabled={isDisabled}
          onKeyDown={e => e.preventDefault()}
        />
      </div>
        {selectedDate && !isDisabled && (
        <HighlightOff
          className={classnames(styles.clear, closeButtonClassName, {
            [styles.isDisabled]: isDisabled,
          })}
          onClick={e => {
            e.stopPropagation()
            onChange(null)
          }}
        />
      )}
      </div>  
  )
}

export default DateInput
