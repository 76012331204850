import React, { type FC } from 'react'

import { DownloadOutlined } from '@mui/icons-material'
import classnames from 'classnames'

import CopyDataButton from '../../../components/Buttons/CopyDataButton'
import { NominatedBargesTable, Theme } from '../../../components/Table/NominatedBargesTable'
import { type NominatedBarge } from '../../../models/models'

import styles from './styles.module.scss'
import TowActionButton from './TowActionButton'

type BargeListBlockProps = {
  readonly nominatedBarges: NominatedBarge[]
  bargeNamesCallback: () => string
  downloadNominationCsvCallback: () => void
}

const theme: Theme = {
  columns: {
    isAvailable: styles.isAvailableCell,
    tboInfo: styles.tboCell,
    name: styles.nameCell,
    isAtRisk: styles.isAtRiskCell,
    cargo: styles.cell,
    destination: styles.cell,
    hullType: styles.hullTypeCell,
    tripStatus: styles.cell,
    pickUp: styles.locationCell,
    dropOff: styles.locationCell,
    currentLocation: styles.locationCell,
    pickupType: styles.cell,
    type: styles.cell,
    fleet: styles.cell,
  },
}

const BargeListBlock: FC<BargeListBlockProps> = ({ nominatedBarges, bargeNamesCallback, downloadNominationCsvCallback }) => {
  return (
    <section className={classnames(styles.container)}>
      <div className={classnames(styles.nominatedBarges)}>
        <header className={classnames(styles.bargeListHeader)}>
          <TowActionButton
            label="Export data to CSV"
            getData={downloadNominationCsvCallback}
            Icon={DownloadOutlined}
            className={styles.towActionButton} 
          />
          <CopyDataButton
            getData={bargeNamesCallback}
            successText="Copied barge names ✔"
            tooltipText="Copy barge names  from list"
          />
        </header>
        <div className={classnames(styles.bargeList)}>
          <NominatedBargesTable hasReviews barges={nominatedBarges} theme={theme} />
        </div>
      </div>
    </section>
  )
}

export default BargeListBlock
