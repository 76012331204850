import { useCallback, useEffect, useMemo, useState } from 'react'

import * as R from 'ramda'
import { useLocation } from 'wouter'
import { number as YupNumber, object as YupObject, string as YupString } from 'yup'

import { fromBargesQuery, isEmpty, minNumberOfRakes, OverviewBarge } from '../../../Domain/Barge'
import { getExcludedBargeTypes, isExcludingHoppers, isExcludingTanks } from '../../../Domain/BargeType'
import { excludePlaceToLoadTripStatusIds, isExcludingPlacedToLoad } from '../../../Domain/Trip'
import {
  GoalId,
  HullType,
  type GraphqlNewNominationRequest,
  OverviewNominationVersionType,
  useLaneBargesQuery,
  type GraphqlVersionRequest,
} from '../../../generated/graphql'
import useNominationActionsModel from '../../../models/useNominationActionsModel'
import { useNominationRequestBridge } from '../../../providers/NominationRequestBridge'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { errorToast, successToast } from '../../../ui/Toast/Toast'

import { createFormData } from './form'
import { createStageNavigation } from './navigation'
import {
  buildDepartureTimeDescription,
  buildIncludeTBOsDescription,
  buildLaneDescription,
  buildMaxDraftDescription,
  buildTowParametersDescription,
  buildVesselDescription,
} from './selectors'
import {
  DepartureTimeFormValues,
  isDepartureTimeSelectionStage,
  isLaneSelectionStage,
  isPoolFiltersSelectionStage,
  isTowParametersSelectionStage,
  isVesselSelectionStage,
  LaneSelectionFormValues,
  NominationFormViewModel,
  NominationStage,
  PoolFiltersSelectionFormValues,
  StageData,
  TowParametersFormValues,
  VesselSelectionFormValues,
} from './types'
import { filterDestinations, filterOrigins, toBargePoolRequestParameters } from './utilities'

import type { VersionedNominationRequest } from '../../../models/models'

const MINIMAL_NR_OF_BARGES = 2

export const collectTotals = (data: TowParametersFormValues, barges: OverviewBarge[]) => {
  const totalNumberOfBarges = R.length(barges)

  const totals = R.reduce(
    (acc, barge) => ({
      empties: acc.empties + (isEmpty(barge) ? 1 : 0),
      rakes: acc.rakes + (barge.barge.hullType === HullType.Rake ? 1 : 0),
    }),
    { empties: 0, rakes: 0 },
    barges
  )

  const totalNumberOfLoaded = totalNumberOfBarges - totals.empties
  const total = Math.max(data.numberOfLoaded, MINIMAL_NR_OF_BARGES) + data.numberOfEmpties

  const remaining = {
    numberOfLoaded: Math.max(totalNumberOfLoaded - data.numberOfLoaded, 0),
    numberOfEmpties: Math.max(totals.empties - data.numberOfEmpties, 0),
    numberOfRakes: Math.max(totals.rakes - minNumberOfRakes(data.numberOfLoaded + data.numberOfEmpties), 0),
    numberOfBarges: Math.max(totalNumberOfBarges - total, 0),
  }

  return {
    totalNumberOfLoaded,
    totalNumberOfEmpties: totals.empties,
    remaining,
    totalNumberOfBarges,
  }
}

export const laneSelectionInitialValues: LaneSelectionFormValues = {
  laneId: undefined,
  origin: undefined,
  destination: undefined,
}

export const laneSelectionValidationSchema = YupObject().shape(
  {
    laneId: YupString().required('Required'),
    origin: YupString().required('Required'),
    destination: YupString().required('Required'),
  },
  []
)

export const includeTBOsValidationSchema = YupObject().shape({
  tboInput: YupNumber().positive('TBO number must be positive').integer('TBO number must be an integer'),
})

export const towParametersValidationSchema = YupObject().shape({
  numberOfLoaded: YupNumber().required('Required').positive('Must be positive'),
  numberOfEmpties: YupNumber().required('Required').positive('Must be positive'),
  numberOfStrings: YupNumber().nullable(),
  goal: YupString().nullable().oneOf(Object.values(GoalId)),
})

type FormState = {
  currentStage: NominationStage
  isSubmitting: boolean
  laneSelectionForm: LaneSelectionFormValues
  vesselSelectionForm: VesselSelectionFormValues
  departureTimeSelectionForm: DepartureTimeFormValues
  poolFiltersSelectionForm: PoolFiltersSelectionFormValues
  towParametersSelectionForm: TowParametersFormValues
}

const initialNavigationState = {
  stage: NominationStage.LaneSelection,
}

const initialFormState: FormState = {
  currentStage: NominationStage.LaneSelection,
  isSubmitting: false,
  laneSelectionForm: {
    laneId: undefined,
    origin: undefined,
    destination: undefined,
  },
  vesselSelectionForm: {
    boatId: undefined,
    hasTurnboat: false,
  },
  departureTimeSelectionForm: {
    selectedDate: null,
    time: null,
  },
  poolFiltersSelectionForm: {
    feet: null,
    inch: null,
    tanks: false,
    openHopper: false,
    havingTboInfo: true,
    placedToLoad: false,
    shuttleMoves: true,
    includeTBOs: [],
  },
  towParametersSelectionForm: {
    numberOfLoaded: 0,
    numberOfEmpties: 0,
    numberOfStrings: null,
    goal: null,
    hotBarges: true,
  },
}

const formStateFromRequest = (request: VersionedNominationRequest): FormState => {
  const { bargeFilters, towConfiguration } = request

  const laneSelectionForm = {
    laneId: bargeFilters.lane,
    origin: bargeFilters.towOrigin,
    destination: bargeFilters.towDestination,
  }
  const vesselSelectionForm = {
    boatId: towConfiguration.boat,
    hasTurnboat: towConfiguration.hasTurnboat,
  }

  const towParametersSelectionForm = {
    numberOfLoaded: towConfiguration.numberOfBarges - towConfiguration.numberOfEmptyBarges,
    numberOfEmpties: towConfiguration.numberOfEmptyBarges,
    numberOfStrings: towConfiguration.numberOfStrings,
    goal: towConfiguration.goal,
    hotBarges: towConfiguration.prioritizeHotBarges,
  }

  const maxDraftFeet = bargeFilters.maximumDraft ? Math.floor(bargeFilters.maximumDraft / 12) : null
  const maxDraftInch = bargeFilters.maximumDraft ? bargeFilters.maximumDraft % 12 : null
  const poolFiltersSelectionForm = {
    feet: maxDraftFeet,
    inch: maxDraftInch,
    tanks: isExcludingTanks(bargeFilters.excludeBargeTypes),
    openHopper: isExcludingHoppers(bargeFilters.excludeBargeTypes),
    havingTboInfo: bargeFilters.excludeTboInfoBarges,
    placedToLoad: isExcludingPlacedToLoad(bargeFilters.excludeTripStatuses),
    shuttleMoves: bargeFilters.excludeShuttleMoves,
    includeTBOs: bargeFilters.includeTBOs,
  }

  const { expectedDepartureTime } = bargeFilters
  const expectedDate = expectedDepartureTime
    ? new Date(expectedDepartureTime.getFullYear(), expectedDepartureTime.getMonth(), expectedDepartureTime.getDate())
    : null
  const expectedTime = expectedDepartureTime
    ? { hours: expectedDepartureTime.getHours(), minutes: expectedDepartureTime.getMinutes() }
    : null
  const departureTimeSelectionForm = {
    selectedDate: expectedDate,
    time: expectedTime,
  }

  return {
    currentStage: NominationStage.LaneSelection,
    isSubmitting: false,
    laneSelectionForm,
    vesselSelectionForm,
    departureTimeSelectionForm,
    poolFiltersSelectionForm,
    towParametersSelectionForm,
  }
}

const toNominationRequestPayload = (data: FormState): GraphqlNewNominationRequest => {
  return {
    configuration: {
      boat: data.vesselSelectionForm.boatId,
      excludeBargeIds: [],
      goal: data.towParametersSelectionForm.goal!,
      hasTurnboat: data.vesselSelectionForm.hasTurnboat,
      includeBargeIds: [],
      numberOfBarges: data.towParametersSelectionForm.numberOfEmpties + data.towParametersSelectionForm.numberOfLoaded,
      numberOfEmptyBarges: data.towParametersSelectionForm.numberOfEmpties,
      numberOfStrings: data.towParametersSelectionForm.numberOfStrings,
      prioritizeHotBarges: data.towParametersSelectionForm.hotBarges,
    },
    filters: {
      excludeBargeTypes: getExcludedBargeTypes(
        data.poolFiltersSelectionForm.tanks,
        data.poolFiltersSelectionForm.openHopper
      ),
      excludeNonNominatableBarges: true,
      excludeShuttleMoves: data.poolFiltersSelectionForm.shuttleMoves,
      excludeTboInfoBarges: data.poolFiltersSelectionForm.havingTboInfo,
      excludeTripStatuses: data.poolFiltersSelectionForm.placedToLoad ? excludePlaceToLoadTripStatusIds : [],
      expectedDepartureTime: null,
      includeTBOs: data.poolFiltersSelectionForm.includeTBOs,
      lane: data.laneSelectionForm.laneId!,
      maximumDraft:
        data.poolFiltersSelectionForm.feet || data.poolFiltersSelectionForm.inch
          ? (data.poolFiltersSelectionForm.feet ?? 0) * 12 + (data.poolFiltersSelectionForm.inch ?? 0)
          : null,
      towDestination: data.laneSelectionForm.origin!,
      towOrigin: data.laneSelectionForm.destination!,
    },
  }
}

const toVersionRequestPayload = (nominationId: string, data: FormState): GraphqlVersionRequest => {
  return {
    ...toNominationRequestPayload(data),
    nominationId,
  }
}

const useNominationFormViewModel = (id: string | null): NominationFormViewModel => {
  const { clearNominationRequest, nominationRequest, nominationVersionType, nominationId, nominationVersionId } =
    useNominationRequestBridge()
  const { createNomination, createVersion } = useNominationActionsModel()

  useEffect(() => {
    if (!id) {
      clearNominationRequest()
    }
  }, [clearNominationRequest, id])

  const [stage, setStage] = useState(initialNavigationState.stage)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [, navigate] = useLocation()

  // const [laneSelectionForm, changeLaneSelectionForm] = useState(laneSelectionInitialValues)
  // const [vesselSelectionForm, changeVesselSelectionForm] = useState({})
  // const [departureTimeSelectionForm, changeDepartureTimeSelectionForm] = useState({})
  // const [towParametersSelectionForm, changeTowParametersSelectionForm] = useState({})

  const withBanner = !!(id && nominationVersionType === OverviewNominationVersionType.External)
  const initialState = id && nominationRequest ? formStateFromRequest(nominationRequest) : initialFormState
  const [formState, setFormState] = useState<FormState>(initialState)

  const { lanes, hubs, boats, goals } = useSettingsContext()

  const bargePoolParameters = toBargePoolRequestParameters(
    formState.laneSelectionForm,
    formState.departureTimeSelectionForm,
    formState.poolFiltersSelectionForm
  )

  const [{ data: bargesInPool, fetching: isFetchingBargePool }] = useLaneBargesQuery({
    variables: bargePoolParameters,
  })
  const [pinnedBarges, setPinnedBarges] = useState<string[]>([])
  const [excludedBarges, setExcludedBarges] = useState<string[]>([])

  const bargePool = useMemo(
    () => ({
      lane: formState.laneSelectionForm.laneId,
      origin: formState.laneSelectionForm.origin,
      destination: formState.laneSelectionForm.destination,
      barges: bargesInPool ? fromBargesQuery(bargesInPool.lanes[0].barges) : [],
      isFetching: isFetchingBargePool,
      pinnedBarges,
      setPinnedBarges,
      excludedBarges,
      setExcludedBarges,
    }),
    [formState, bargesInPool, isFetchingBargePool, pinnedBarges, excludedBarges]
  )

  const submitAction = useCallback(async () => {
    setIsSubmitting(true)
    const callback = id
      ? () => createVersion(toVersionRequestPayload(id, formState))
      : () => createNomination(toNominationRequestPayload(formState))

    try {
      const { nominationId: newNominationId, versionId } = await callback()
      successToast(`${id ? 'Nomination version' : 'Nomination'} successfully created`)
      setIsSubmitting(false)
      navigate(`/nomination/${newNominationId}/version/${versionId}`)
    } catch (e: any) {
      errorToast(e.message)
      setIsSubmitting(false)
    }
  }, [id, formState, createVersion, createNomination, navigate])

  const updateLaneSelectionForm = (values: LaneSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      laneSelectionForm: values,
    }))
  }

  const updateVesselSelectionForm = (values: VesselSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      vesselSelectionForm: values,
    }))
  }

  const updatePoolFiltersForm = (values: PoolFiltersSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      poolFiltersSelectionForm: {
        ...prev.poolFiltersSelectionForm,
        ...values,
      },
    }))
  }

  const updateTowParametersSelectionForm = (values: TowParametersFormValues) => {
    setFormState(prev => {
      const updatedState = {
        ...prev,
        towParametersSelectionForm: {
          ...prev.towParametersSelectionForm,
          ...values,
        },
      }
      return updatedState
    })
  }

  const currentStage = {
    stage,
  }

  const laneSelectionInitParams = useMemo(() => {
    const { laneId, origin } = formState.laneSelectionForm
    const origins = filterOrigins(laneId, hubs)
    const destinations = filterDestinations(laneId, origin, hubs)
    return {
      lanes,
      origins,
      destinations,
    }
  }, [formState, lanes, hubs])

  const vesselSelectionInitParams = useMemo(
    () => ({
      boats,
    }),
    [boats]
  )

  const towParametersInitParams = useMemo(() => {
    return {
      goals,
      currentGoal: formState.towParametersSelectionForm.goal,
      barges: bargePool.barges,
    }
  }, [formState.towParametersSelectionForm.goal, goals, bargePool.barges])

  const hotBargeCount = useMemo(
    () => towParametersInitParams.barges.filter(b => b.isHot).length,
    [towParametersInitParams.barges]
  )

  const backHandler = useCallback(() => {
    const backUrl =
      nominationId && nominationVersionId
        ? `/nomination/${nominationId}/version/${nominationVersionId}`
        : '/nominations'
    navigate(backUrl)
  }, [nominationId, nominationVersionId, navigate])

  const stages: Record<NominationStage, StageData<any, any, any>> = {
    [NominationStage.LaneSelection]: {
      isSelected: isLaneSelectionStage(currentStage),
      form: createFormData(formState.laneSelectionForm, updateLaneSelectionForm),
      summary: { description: buildLaneDescription(formState.laneSelectionForm, lanes, hubs) },
      initParameters: laneSelectionInitParams,
      actions: createStageNavigation(NominationStage.LaneSelection, setStage),
    },
    [NominationStage.VesselSelection]: {
      isSelected: isVesselSelectionStage(currentStage),
      form: createFormData(formState.vesselSelectionForm, updateVesselSelectionForm),
      summary: {
        description: buildVesselDescription(formState.vesselSelectionForm, boats),
      },
      initParameters: vesselSelectionInitParams,
      actions: createStageNavigation(NominationStage.VesselSelection, setStage),
    },
    [NominationStage.DepartureTimeSelection]: {
      isSelected: isDepartureTimeSelectionStage(currentStage),
      form: createFormData(formState.departureTimeSelectionForm, values =>
        setFormState(prev => ({ ...prev, departureTimeSelectionForm: values }))
      ),
      summary: {
        description: buildDepartureTimeDescription(
          formState.departureTimeSelectionForm.selectedDate,
          formState.departureTimeSelectionForm.time
        ),
      },
      actions: createStageNavigation(NominationStage.DepartureTimeSelection, setStage),
    },
    [NominationStage.PoolFiltersSelection]: {
      isSelected: isPoolFiltersSelectionStage(currentStage),
      form: createFormData(formState.poolFiltersSelectionForm, updatePoolFiltersForm),
      summary: {
        description: [
          buildMaxDraftDescription(formState.poolFiltersSelectionForm.feet, formState.poolFiltersSelectionForm.inch),
          buildIncludeTBOsDescription(formState.poolFiltersSelectionForm.includeTBOs || []),
        ],
      },
      actions: createStageNavigation(NominationStage.PoolFiltersSelection, setStage),
    },
    [NominationStage.TowParametersSelection]: {
      isSelected: isTowParametersSelectionStage(currentStage),
      form: createFormData(formState.towParametersSelectionForm, updateTowParametersSelectionForm),
      summary: {
        description: buildTowParametersDescription(formState.towParametersSelectionForm, hotBargeCount),
      },
      initParameters: towParametersInitParams,
      actions: createStageNavigation(NominationStage.TowParametersSelection, setStage),
    },
  }

  return {
    currentStage,
    isSubmitting,
    stages,
    bargePool,
    withExternalVersionBanner: withBanner,
    submit: submitAction,
    backHandler,
  }
}

export default useNominationFormViewModel
